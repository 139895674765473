import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Login(props) {
    const router = useRouter();
    const query = router.query;
    const token = query?.token;

    if (token) {
        if (typeof window !== 'undefined') {
            localStorage.setItem('SavedToken', `Bearer ${token}`);
            router.push('/dashboard');
        }
    }

    return <div className=""></div>;
}
